<template>
  <div class="c">
    <div style="height: 30px; text-align: center">
      <span v-if="user"> {{ user.name }}</span>
      <span v-if="this.$store.state.division">
        @ {{ this.$store.state.division.name }}</span
      >
    </div>
    <router-link :to="'/'" class="btn btnb w-50">MENU</router-link>
    <button class="btn btnb w-25" @click="goBack">BACK</button>
    <button class="btn btnb w-25" @click="logout">
      <i class="bi bi-power"></i>
    </button>
  </div>
</template>


<script>
import axios from "axios";
import store from "@/store";
export default {
  setup() {},
  data() {
    return {
      user: store.state.user,
      division: store.state.division,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async logout() {
      try {
        const response = await axios.post("/logout");
        localStorage.removeItem("token");
        this.$router.push("/login");
        this.$store.commit("reset");
        this.msg = response.data.text;
      } catch (err) {
        console.log("error");
      }
    },
  },
};
</script>

<style scoped>
.btnb {
  padding: 0.75em;
  font-size: 1em;
  background-color: rgba(63, 63, 63, 0.459);
  border: thin solid #96969670;
  height: 50px;
}
.c {
  border-top: solid thin #79797970;
  height: 50px;
}
</style>