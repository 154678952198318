import { createRouter, createWebHistory } from "vue-router";


const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home/DashBoard"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Auth/LoginView"),
  },
  {
    path: "/division_selection",
    name: "Divisions",
    component: () => import("../views/Auth/DivisionSelection"),
  },
  {
    path: "/sales_invoice",
    name: "Invoice",
    component: () => import("../views/Sales/InvoiceView"),
  },
    {
    path: "/sales_invoice_ov",
    name: "Invoice Ov",
    component: () => import("../views/Sales/InvoiceOv"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

import store from "@/store";
router.beforeEach((to, from, next) => {
  document.title = "MAHAWATHTHA | " + to.name;
  if (to.name !== "Login" && !store.state.user) {
    next({ name: "Login" });
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});
export default router;
